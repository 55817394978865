'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:UserPermissionsManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'UserPermissionsManager', [
    'Restangular'
    'MundoDefaultManager'
    '_'
    '$log'
    (
      Restangular
      MundoDefaultManager
      _
      $log
    ) ->
      UserPermissionsManagerBase = new MundoDefaultManager()

      UserPermissionsManagerBase.setUrl('permissions')
      UserPermissionsManagerBase.setActions(['list'])

      UserPermissionsManagerBase
  ]
